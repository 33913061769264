import apiConfig from "@/config/api.config";
import axios from "axios";
import { responseInterceptor } from "../http/interceptors";
import { identityRequestInterceptor } from "./identity.interceptors";

/**
 * Create Identity client
 * @public
 */
export const $identity = axios.create({
  baseURL: apiConfig.API_BASE_URL,
});

$identity.interceptors.request.use(identityRequestInterceptor);

$identity.interceptors.response.use(undefined, responseInterceptor);
