import { $identity } from "@/services/apis/";

export const getPrefferences = (email) => {
  return $identity.get(`session/preferences/${email}`);
};

export const updatePrefferences = (email, payload) => {
  return $identity.patch(`session/preferences/${email}`, payload);
};

export const listTimezones = () => {
  return $identity.get(`/api/v1/time_zones`);
};
